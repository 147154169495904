<template>
  <div class="form-1">
    <slot name="left"></slot>
    <slot name="right"></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.form-1{
  display:flex;
  justify-content: space-between;
  align-items: center;
}
.form-1 .el-form-item{
    margin-bottom: 0px;
  }
</style>