<template>
<div>
  <div style="height:10px;"></div>
  <el-pagination
      @current-change="handleCurrentChange"
      :current-page="pageinfo.currentpage"
      :page-size="pageinfo.pageSize||10"
      layout="total, prev, pager, next, jumper"
      :total="pageinfo.total">
    </el-pagination>
</div>
</template>
<script>
export default {
  props:['pageinfo'],
  methods: {
    handleCurrentChange(val){
      this.$emit('handlePageClick',val)
    }
  }
}
</script>