<!-- 使用案例 -->
<!-- 
	<table-template :table_data="table_data" width="180" :format-list="['experimenttype','basetype']">
		<template slot="experimenttype" slot-scope="scope">
		</template>
		<template slot="basetype" slot-scope="scope">
		</template>
		<template slot-scope="scope">
			<div class="zzjz_divider">
				<el-link :underline="false" type="primary" @click="handleClickExperimentBank({type:'手册', row:scope.scope.row})">手册</el-link>
				<el-link :underline="false" type="primary" @click="handleClickExperimentBank({type:'编辑', row:scope.scope.row})">编辑</el-link>
				<el-link :underline="false" :disabled="scope.scope.row.basetype == 0" type="danger"  @click="handleClickExperimentBank({type:'删除', row:scope.scope.row})">删除</el-link>
			</div>
		</template>
	</table-template>
	-->
<template>
  <div>
    <el-table style="width: 100%; margin-top: 10px" :data="table_data.data" :header-cell-style="tableHeaderColor" :highlight-current-row="currentHighlight" ref="multipleTable" :row-key="rowKey" :tree-props="treeProps" :indent="indent" @row-click="clickRowHandle" @selection-change="handleSelectionChange" v-loading="isloading" :default-expand-all="isAllExpand">
      <el-table-column type="selection" width="55" v-if="isSelection"></el-table-column>
      <el-table-column type="expand" v-if="isExpand">
        <template slot-scope="scope">
          <slot name="expand" :scope="scope"></slot>
        </template>
      </el-table-column>
      <el-table-column fixed label="序号" :width="numWidth || 55" v-if="!treeProps">
        <template slot-scope="scope">
          {{
            scope.$index +
            1 +
            (table_data.pageinfo.currentpage - 1) * table_data.pageinfo.pageSize
          }}
        </template>
      </el-table-column>
      <template v-for="(item,inx) in table_data.tableConfig">
        <template v-if="formatList">
          <template v-for="(keyval, idx) in formatList">
            <el-table-column v-if="item.key == keyval" :label="item.name" :key="inx+'-'+idx" :fixed="item.fixed" :prop="item.key" :width="item.minWidth" :align="item.align">
              <template slot-scope="scope">
                <slot :name="keyval" :scope="scope"></slot>
              </template>
            </el-table-column>
          </template>
          <template v-if="formatList.indexOf(item.key) == -1">
            <el-table-column :label="item.name" :fixed="item.fixed" :prop="item.key" :width="item.minWidth" :align="item.align" :key="item.id">
              <template slot-scope="scope">
                {{scope.row[item.key]||'--'}}
              </template>
              <template v-if="item.children">
                <template v-for="obj in item.children">
                  <el-table-column :label="obj.name" :prop="obj.key" :width="obj.minWidth" :align="item.align" :key="obj.key"></el-table-column>
                </template>
              </template>
            </el-table-column>
          </template>
        </template>
        <template v-else>
          <el-table-column :label="item.name" :fixed="item.fixed" :prop="item.key" :width="item.minWidth" :align="item.align" :key="item.id">
            <template v-if="item.children">
              <template v-for="obj in item.children">
                <el-table-column :label="obj.name" :prop="obj.key" :width="obj.minWidth" :align="item.align" :key="obj.key"></el-table-column>
              </template>
            </template>
          </el-table-column>
        </template>
      </template>
      <el-table-column v-if="!isshowopt" fixed="right" label="操作" align="center" :width="width || '240'">
        <template slot-scope="scope">
          <slot :scope="scope"></slot>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  // formatList  用于需要单独处理的列名，值为对应prop属性，eg：参考组件PersonalExam的传值
  // isshowopt 有传值，操作列不显示
  // isSelection true  显示多选框 false 不显示
  // width 设置操作项的宽度，没有则默认120px
  // isshowopt  默认不传，有值传入，操作项不显示
  // rowkey 支持树类型的数据的显示。当 row 中包含 children 字段时，被视为树形数据。渲染树形数据时，必须要指定 row-key。
  // indentVal 树形结构时，缩进大小
  // currentHighlight 高亮当前选中行，默认不选中高亮；传值任意值，做选中高亮 
  props: [
    "table_data",
    "width",
    "formatList",
    "isshowopt",
    "isExpand",
    "rowkey",
    "isSelection",
    "treeProps",
    "indentVal",
    "currentHighlight",
    "isloading",
    "isAllExpand",  // 子集是否默认全部展开
    "numWidth"
  ],
  watch: {
    isloading: {
      handler () {

      },
      immediate: true
    },
  },
  data () {
    return {
      rowKey: "id",
      multipleSelection: [],
      indent: 8
    };
  },
  mounted () {
    if (this.rowkey) {
      this.rowKey = this.rowkey;
    }
  },
  methods: {
    // 修改table header的背景色
    tableHeaderColor ({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: #FAFAFA;color: #333;font-weight: 700;";
      }
    },
    clickRowHandle (row) {
      if (!this.isExpand && !this.treeProps) {
        this.$refs.multipleTable.toggleRowExpansion(row);
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
      this.$emit("handleSelectionChange", val);
    },
  },

};
</script>