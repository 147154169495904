<template>
  <div>
    <!-- <el-card shadow="never" style="margin-bottom: 20px;">
      <div class="static" style="display: flex;justify-content: space-around;align-items: center;">
        <template v-for="(item,inx) in staticObj">
          <div :key="inx">
            <h3>{{item.name}}</h3>
            <p>占比 <span>{{ item.rate }}</span>%</p>
            <p>总数 <span>{{ item.count }}</span></p>
          </div>
          <el-divider direction="vertical" v-if="inx<staticObj.length-1" :key="inx+8"></el-divider>
        </template>
        
      </div>
    </el-card> -->
    <list-condition-form>
      <div slot="left"></div>
      <div slot="right">
        <el-select v-model="contenttypes" placeholder="请选择留言类型" @change="handleChange">
          <el-option v-for="(item,inx) in typeList" :key="inx" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
    </list-condition-form>
    <table-template :treeProps="{children: 'list',hasChildren: 'hasChildren'}" :formatList="['contenttype']" :table_data="table_data" :isloading="isloading">
      <template slot="contenttype" slot-scope="scope">
        <template v-if="scope.scope.row.pid==0">
          {{ typeListObj[scope.scope.row.contenttype] }}
        </template>
      </template>
      <template slot-scope="scope">
        <el-button v-if="scope.scope.row.pid==0" type="primary" size="small" @click="showReply(scope.scope.row, scope.$index)">回复</el-button>
        <el-button type="danger" size="small" @click="delMsg(scope.scope.row)">删除</el-button>
      </template>
    </table-template>
    <div style="text-align:right">
      <base-pageination :pageinfo="table_data.pageinfo" @handlePageClick="handlePageClick"></base-pageination>
    </div>
    <el-dialog title="回复留言" :visible.sync="isshowReply" width="600px" :before-close="handleClose">
      <el-form label-width="100px">
        <el-form-item label="用户留言：">
          {{replyObj.content}}
        </el-form-item>
        <el-form-item label="留言回复：">
          <el-input v-model="reply.content" type="textarea" :rows="5" placeholder="请输入要回复的内容"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" align="center">
        <el-button style="width:100px" type="primary" @click="subRely()">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BasePageination from '../components/BasePageination.vue'
import ListConditionForm from '../components/ListConditionForm.vue'
import TableTemplate from '../components/TableTemplate.vue'
import {
  getMessageList,
  replyMessage,
  deleteMessage,
  getMessageStaticByType
} from '@/api/message.js'
export default {
  components: { ListConditionForm, TableTemplate, BasePageination },
  data () {
    return {
      // content: '0,1,2,3,4,11',
      contenttypes: '0,1,2,3,4,11',
      table_data: {
        tableConfig: [
          {key: 'content', name: '留言内容'},
          {key: 'contenttype', name: '留言类型',minWidth: 120},
          {key: 'username', name: '发布者',minWidth: 120},
          {key: 'createTime', name: '发布时间',minWidth: 160,align:'center'},
        ],
        data: [],
        pageinfo: { currentpage:1, pageSize: 10, total: 0}
      },
      isshowReply: false,
      replyObj: {content:''},
      reply: {
        content: '',
        username: localStorage.getItem('username'),
        pid: ''
      },
      // 5、报装 6 报修 7 查询缴费  8 过户 9 销户 10 电子发票
      typeList: [ '报修', '咨询', '建议', '投诉', '表扬','报装满意度','报修满意度','查询缴费满意度','过户满意度','销户满意度','电子发票满意度','电子发票'],
      typeList: [ 
        {label: '全部', value: '0,1,2,3,4,11'},
        {label: '报修', value: 0},
        {label: '咨询', value: 1},
        {label: '建议', value: 2},
        {label: '投诉', value: 3},
        {label: '表扬', value: 4},
        {label: '电子发票', value: 11}
      ],
      typeListObj: {
        0:'报修', 
        1:'咨询', 
        2:'建议', 
        3:'投诉', 
        4:'表扬',
        5:'报装满意度',
        6:'报修满意度',
        7:'查询缴费满意度',
        8:'过户满意度',
        9:'销户满意度',
        10:'电子发票满意度',
        11:'电子发票'
      },
      isloading: false,
      platformtype: 1,
      staticObj: [{
        name: '非常满意',
        count: 0,
        rate: 0
      },{
        name: "满意",
        count: 0,
        rate: 0
      },{
        name: "一般满意",
        count: 0,
        rate: 0
      }, {
        name:"不满意",
        count: 0,
        rate: 0
      }]
    }
  },
  mounted () {
    if (localStorage.getItem('username') == 'dingyuan') this.platformtype = 2
      else if (localStorage.getItem('username') == 'admin') this.platformtype = 1
    this.handlePageClick(1)
  },
  methods: {
    getStatic() {
      const static_= {
        '非常满意': 0,
        "满意": 0,
        "一般满意": 0,
        "不满意": 0,
      }
      this.staticObj= [{
        name: '非常满意',
        count: 0,
        rate: 0
      },{
        name: "满意",
        count: 0,
        rate: 0
      },{
        name: "一般满意",
        count: 0,
        rate: 0
      }, {
        name:"不满意",
        count: 0,
        rate: 0
      }]
      getMessageStaticByType({
        platformtype: this.platformtype,
        contenttypes: this.contenttypes
      }).then(res=>{
        const {code, data} = res
        if (code == 10000) {
          if (data.length>0) {
            let arr = []
            let countAll = 0
            data.forEach(item=>{
              static_[item.content] = item.count
              countAll += item.count
            })
            this.staticObj.forEach(item=>{
              item.count = static_[item.name]
              item.rate = ((item.count/countAll)*100).toFixed(2)
            })
          }
        }
      })
    },
    handleChange (val) {
      // this.contenttypes = val //val.join(',')
      this.handlePageClick(1)
      this.getStatic()
    },
    handleClear() {
      this.contenttypes = this.content
      this.handlePageClick(1)
      this.getStatic()
    },
    fetchData () {
      this.isloading = true
      getMessageList({
        pageSize: this.table_data.pageinfo.pageSize, 
        pageNum: this.table_data.pageinfo.currentpage, 
        contenttypes: this.contenttypes,
        platformtype: this.platformtype
      }).then(res => {
        this.isloading = false
        const {code, data, page} = res
        if (code === 10000) {
          this.table_data.data = data
          this.table_data.pageinfo = page
        }
      })
    },
    handlePageClick (page) {
      this.table_data.pageinfo.currentpage = page
      this.fetchData()
    },
    showReply (item) {
      this.replyObj = item
      this.reply.pid = item.id
      this.isshowReply = true
    },
    subRely () {
      replyMessage(this.reply).then(res=>{
        const {code, message} = res
        if (code === 10000) {
          this.$message.success('回复已提交')
          this.handleClose()
        } else {
          this.$message.warning('操作失败！')
        }
      })
    },
    handleClose () {
      this.handlePageClick(1)
      this.isshowReply = false
    },
    delMsg (obj) {
      this.$confirm('确认删除该条留言或回复, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteMessage({id: obj.id}).then(res => {
          const {code, message} = res
          if (code === 10000) {
            this.$message.success('操作成功！')
            this.handlePageClick(1)
          } else {
            this.$message.success('操作失败！')
          }
        })
      })
    }
  }
}
</script>

<style>
.el-divider--vertical{
  height:50px;
}
.static div{
  text-align: center;
}
.static div span{
  font-size:20px;
  font-weight: 700;
}
</style>