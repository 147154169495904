import myAxios from "./axios_news"

export const getMessageList = params => {
  return myAxios.get("/message/getMessageList", { params })
}
export const addMessage = params => {
  return myAxios.post("/message/addMessage", params)
}

export const replyMessage = params => {
  return myAxios.post("/message/replyMessage", params)
}
export const deleteMessage = params => {
  return myAxios.post("/message/deleteMessage", params)
}
export const updateMessage = params => {
  return myAxios.post("/message/updateMessage", params)
}
export const getPlUserList = params => {
  return myAxios.get("/plu/getPlUserList", { params })
}
export const updatePlU = params => {
  return myAxios.post("/plu/updatePlU", params)
}

export const getMessageStaticByType = params => {
  return myAxios.get("/message/getMessageStaticByType", { params })
}